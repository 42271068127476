import React from "react";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import MoreInfo from "../sections/get-started/MoreInfo";
import Step1 from "../sections/get-started/Step1";
import Step2 from "../sections/get-started/Step2";
import Step3 from "../sections/get-started/Step3";

const GetStarted = (props) => {
  return (
    <>
      <PageWrapper footerDark headerDark>
        <Seo
          title="Opqo | Get Started | Mobile for Maximo"
          description="Get up and running with Opqo in three simple steps."
          pathname={props.location.pathname}
        />
        <PageTitle titleDark title="Let's Get Started" color="light">
          Three simple steps to get up and running in minutes.
        </PageTitle>
        <Step1 />
        <Step2 />
        <Step3 />
        <MoreInfo />
      </PageWrapper>
    </>
  );
};
export default GetStarted;
