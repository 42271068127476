import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";
import { StepNumberBox } from "./Common";


const Step3 = () => {
  return (
    <>
      <Section className="pt-5">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col lg="2" className="mr-5 pb-3">
              <StepNumberBox number="3" />
            </Col>
            <Col lg="1" />
            <Col lg="7">
              <div>
                <Title>Login to Opqo</Title>
                <Text className="mt-3">
                  The first time you connect Opqo to a Maximo environment, we'll need to perform some
                  Maximo configurations.
                </Text>
                <Text mt={3}>
                  Full details of the configuration that Opqo performs can be found
                  in our <a href="https://support.sharptree.io" target="_blank" rel="noopener noreferrer">
                    <u>Support Portal</u></a>.
                </Text>
                <Text className="mt-3">
                  Login to the Opqo app on your device using a Maximo account
                  that is in the system administrators group.
                </Text>
                <Text className="mt-3">
                  Follow the prompts and Opqo will perform all necessary configuration, automatically.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Step3;