import React from "react";
import styled from "styled-components";
import { Box } from "../../components/Core";
import { device } from "../../utils";

export const NumberBlock = styled(Box)`
    width: 215px;
    height: 213px;
    border-radius: 8px;
    text-align: center;
    margin: 0 auto;
    @media ${device.md} {
    margin-left: 0;
    }
    span {
    font-size: 140px;
    font-weight: 900;
    letter-spacing: -4.38px;
    }
`;

export const StepNumberBox = ({ number, color = "text", bg = "greyBackground" }) => {
  return (
    <NumberBlock color={color} bg={bg} >
      <span>{number}</span>
    </NumberBlock>
  );
};

