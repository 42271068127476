import { Link } from "gatsby";
import { rgba } from "polished";
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Box, Button, Section, Text, Title } from "../../components/Core";
import { device } from "../../utils";


const SectionStyled = styled(Section)``;

const OfferWrapper = styled(Box)`
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.light, 0.05)};
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 50px;
`;

const BtnContainer = styled(Box)`
  margin-left: 45px;
  margin-top: 30px;

  @media ${device.lg} {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const MoreInfo = () => (
  <>
    <SectionStyled bg="ash">
      <Container>
        <OfferWrapper>
          <div className="d-flex flex-wrap">
            <div className="py-3 pl-5">
              <Title color="light">
                Have questions?
              </Title>
              <Text color="light">
                Or let us know if you'd like try Opqo in one of our demo environments.
              </Text>
            </div>
            <BtnContainer>
              <Link to="/contact">
                <Button variant="outline">Contact Us</Button>
              </Link>
            </BtnContainer>
          </div>
        </OfferWrapper>
      </Container>
    </SectionStyled>
  </>
);

export default MoreInfo;
