import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";
import { StepNumberBox } from "./Common";


const Step2 = () => {
  return (
    <>
      <Section className="pt-5">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col lg="2" className="mr-5 pb-5">
              <StepNumberBox number="2" />
            </Col>
            <Col lg="1" />
            <Col lg="7">
              <div>
                <Title>Specify Maximo Connection Details</Title>
                <Text>
                  Launch Opqo on your device.
                </Text>
                <Title variant="card" className="mt-5">Setup a Configuration Code</Title>
                <Text mt={3}>
                  Configuration codes allow you to choose a simple word or phrase for your users
                  to enter into Opqo to connect to your Maximo environment.
                </Text>
                <Text mt={3}>
                  To setup a configuration code for your Maximo environment, register a free account
                  at our <a href="https://console.sharptree.io" target="_blank" rel="noopener noreferrer">
                    <u>Customer Console</u></a>.
                </Text>
                <Text mt={3}>
                  Select <b>Manage Configuration</b> and add an entry for your environment, along with your
                  desired configuration code.
                </Text>
                <Text mt={3}>
                  Then return to the Opqo app on your device, select <b>Enter Configuration Code</b> and
                  enter the configuration code you specified.
                </Text>
                <Text mt={3}>
                  Opqo will retrieve your Maximo connection details. Tap the button for your Maximo environment and you're all set.
                </Text>
                <Title variant="card" className="mt-5">Or, Manually Specify</Title>
                <Text mt={3}>
                  Alternatively, select <b>Enter Manually</b> and enter your Maximo environment details.
                </Text>
                <Text mt={3}>
                  Typically, this is simply the Maximo host name.  For example, if you access Maximo via:
                </Text>
                <Text mt={3}>
                  <u>https://opqo-demo.sharptree.io/maximo</u>
                </Text>
                <Text mt={3}>
                  the Maximo hostname to enter is:
                </Text>
                <Text mt={3}>
                  <b>opqo-demo.sharptree.io</b>
                </Text>
                <Text mt={3}>
                  Enter other settings as appropriate, such as for non-SSL connections or a custom port (tap the <b>MORE</b> button), and
                  tap <b>Submit</b>.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Step2;