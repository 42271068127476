import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

import { StoreBadgeBar } from "../../components/StoreBadge";
import { StepNumberBox } from "./Common";

const Step1 = () => {
  return (
    <>
      <Section>
        <Container>
          <Row className="justify-content-center mb-4">
            <Col lg="2" className="mr-5 pb-3">
              <StepNumberBox number="1" />
            </Col>
            <Col lg="1" />
            <Col lg="7">
              <div>
                <Title>Download Opqo</Title>
                <Text>
                  Download the Opqo app to your iOS or Android device.
                </Text>
                <div className="d-flex flex-column align-items-start">
                  <StoreBadgeBar />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Step1;